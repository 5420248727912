import React, {useContext, useState, useEffect} from 'react'
import Default from 'gatsby-theme-carbon/src/templates/Default';
import JobBody from './../components/job-body'
import { graphql } from "gatsby"
import {UserContext} from '@parallelpublicworks/entitysync';
import { formatJobData } from './../util';
import { ToastNotification} from 'carbon-components-react';


function JobComponent({data, pageContext, location}) {

  const userContext = useContext(UserContext);
  const userData = userContext && userContext[0] && userContext[0].userData ? userContext[0].userData : null;

  const [id, setId] = useState(0)
  const [newAdd, setNewAdd] = useState(false)
  const [jobObject, setJobObject] = useState(null)
  const [jobData, setJobData] = useState(null)

  useEffect(() => {
    if(typeof window !== `undefined`){
      const queryString = window.location.search;
      if(queryString){
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get('id')) setId(urlParams.get('id'))
        if(urlParams.get('new')) setNewAdd(true)
      }
    }
  }, [])

  useEffect(() => {
    if(id && !jobObject){
      if(userData && userData[id]){
        setJobObject(userData[id])
      }
    }
  }, [id])

  useEffect(() => {
    if(jobObject){
      let job_data = formatJobData(jobObject, data)
      setJobData(job_data)
    }
  }, [jobObject])

  pageContext = jobData ? {...pageContext, frontmatter: {title: jobData.title}} : {...pageContext, frontmatter: {title: ''}};

  return (
    <Default pageContext={pageContext}  location={location}>
      {jobData && <JobBody job_data={jobData} />}
      {newAdd && <div className="toast-container">
      <ToastNotification
        caption=""
        iconDescription="Close"
        subtitle=""
        timeout={0}
        title="Job listing created"
        kind="success"
        lowContrast="true"
      />
      </div>}
    </Default>
  )
}

export default function Preview({pageContext, location, data}) {
  return (
    <JobComponent pageContext={pageContext}  location={location} data={data} />
  );
}

export const query = graphql`
query {
  allNodeJobListing(sort: {fields: field_publish_date, order: DESC}, filter: {changed: {gt: "2022-01-01"}, field_publish_date: {gt: "2022-01-01"}}) {
    nodes {
      drupal_id
      changed
      title
      field_expired
      field_publish_date
      field_archived
      field_company_name
      field_preferred_qualifications {
        value
      }
      field_required_qualifications {
        value
      }
      field_description {
        value
      }
      field_benefits {
        value
      }
      field_address {
        address_line1
        administrative_area
        locality
        postal_code
      }
      field_salary_number
      field_salary_number_max
      relationships {
        uid {
          drupal_id
        }
        field_category {
          drupal_id
          name
        }
        field_experience_level {
          drupal_id
          name
        }
        field_position {
          drupal_id
          name
        }
        field_salary_type {
          drupal_id
          name
          description {
            processed
          }
        }
        field_expiration {
          drupal_id
          name
        }
        field_application_method {
          drupal_id
          name
        }
        field_education {
          drupal_id
          name
        }
        field_bilingual {
          drupal_id
          name
        }
        field_experience_years {
          drupal_id
          name
        }
      }
      field_required_documents
      field_application_url {
        uri
      }
    }
  }
  allTaxonomyTermDatesPosted {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermApplicationMethods {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermCategories {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermPositionTypes {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermSalaryTypes {
    nodes {
      id
      drupal_id
      name
      description {
        processed
      }
    }
  }
  allTaxonomyTermExperienceLevels {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermDistances {
    nodes {
      id
      drupal_id
      name
    }
  }  
  allTaxonomyTermExpirationLengths {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermExperienceYears {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermEducationLevels {
    nodes {
      id
      drupal_id
      name
    }
  }
  allTaxonomyTermBilingual {
    nodes {
      id
      drupal_id
      name
    }
  }
}
`;
